        <template>
  <div
      >
    <b-row
          >
                                    <b-col
                                                                                                >
                            <e-commerce-checkout />            </b-col>
                            </b-row>
  </div>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import ECommerceCheckout from '@core/components/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'
                            
export default {
  components: {
    BRow,
    BCol,

                                                          ECommerceCheckout,
                                          },
  setup() {
    
      },
}
</script>
    
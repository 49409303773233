<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <b-card
      v-if="paymentDetails"
      no-body
    >
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Способ оплаты</b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="match-height">
          <b-col
            v-for="(payment, key) in paymentDetails.payments"
            :key="key"
            md="4"
          >
            <b-card
              :img-src="server + payment.image"
              img-top
              :img-alt="payment.name"
              :border-variant="payment.default === true ? 'primary' : ''"
              class="cursor-pointer"
              @click="selectPayment(payment)"
            >
              <b-card-text>
                <h5>{{ payment.name }}</h5>
              </b-card-text>
              <b-card-text v-if="payment.description">
                <div v-html="payment.description" />
              </b-card-text>
              <b-card-text>
                <strong v-if="payment.priceText"><span v-html="payment.priceText" /></strong>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Checkout Options -->
    <e-commerce-checkout-payment-price-details
      label="Отправить заказ"
      :end="true"
    />
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle,
  BRow,
  BCol,
  BCardText,
  BCardBody,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ECommerceCheckoutPaymentPriceDetails from './ECommerceCheckoutPaymentPriceDetails.vue'
import store from '@/store'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    ECommerceCheckoutPaymentPriceDetails,
  },
  setup() {
    const paymentDetails = ref(null)

    const fetchCartPay = () => {
      store.dispatch('app-ecommerce/fetchCartPay')
        .then(response => {
          const { data } = response.data
          if (data) {
            paymentDetails.value = { ...data[0] }
            store.commit('app-ecommerce/UPDATE_CART_PAYMENT_TOTALS', data[0].priceDetail)
            store.commit('app-ecommerce/UPDATE_CART_PAYMENT_DATA', data[0])
          }
        })
    }

    const selectPayment = payment => {
      paymentDetails.value.payments.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.default = false
      })
      // eslint-disable-next-line no-param-reassign
      payment.default = true

      store.commit('app-ecommerce/UPDATE_ORDER_DATA', {
        pay: {
          value: payment.value,
          accSum: store.state['app-ecommerce'].paumentData.acc.balanceForPay,
        },
      })

      store.dispatch('app-ecommerce/addCartPay', {
        xparam1: JSON.stringify({
          value: payment.value,
          accSum: store.state['app-ecommerce'].paumentData.acc.balanceForPay,
        }),
      })
        .then(() => {
          store.dispatch('app-ecommerce/fetchCartPay')
            .then(response => {
              const { data } = response.data
              if (data) {
                store.commit('app-ecommerce/UPDATE_CART_PAYMENT_TOTALS', data[0].priceDetail)
              }
            })
        })
    }

    const server = process.env.VUE_APP_SERVER

    fetchCartPay()

    return {
      server,
      selectPayment,
      paymentDetails,
      fetchCartPay,
    }
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>

<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      class="list-view product-checkout"
      @submit.prevent="handleSubmit(onSubmit)"
    >

      <!-- Left Form -->
      <b-card
        v-if="!$store.state['app-ecommerce'].cartAddress"
        no-body
        class="relative"
      >
        <b-overlay
          :show="true"
          no-wrap
          :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
        />
      </b-card>
      <b-card
        v-if="$store.state['app-ecommerce'].cartAddress"
        no-body
      >
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Покупатель:</b-card-title>
          <b-card-text class="text-muted mt-25" />
        </b-card-header>
        <b-card-body>
          <b-row>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="LN"
                rules="required"
              >
                <b-form-group
                  label="Фамилия"
                  label-for="LN"
                  class="mb-2"
                >
                  <b-form-input
                    id="LN"
                    v-model="$store.state['app-ecommerce'].cartAddress.LN"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="FN"
                rules="required"
              >
                <b-form-group
                  label="Имя"
                  label-for="FN"
                  class="mb-2"
                >
                  <b-form-input
                    id="FN"
                    v-model="$store.state['app-ecommerce'].cartAddress.FN"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group
                  label="E-mail"
                  label-for="email"
                  class="mb-2"
                >
                  <b-form-input
                    id="email"
                    v-model="$store.state['app-ecommerce'].cartAddress.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="tel"
              >
                <b-form-group
                  label="Телефон"
                  label-for="tel"
                  class="mb-2"
                >
                  <b-form-input
                    id="tel"
                    v-model="$store.state['app-ecommerce'].cartAddress.tel"
                    v-mask="'+7(###) ###-####'"
                    :state="getValidationState(validationContext)"
                    placeholder="+7(987) 654-3210"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="12">
              <hr>
            </b-col>

            <b-col
              sm="12"
              class="mt-1"
            >
              <b-card-title>Адрес:</b-card-title>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="postcode"
                rules="required"
              >
                <b-form-group
                  label="Индекс"
                  label-for="postcode"
                  class="mb-2"
                >
                  <b-form-input
                    id="postcode"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.postcode"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="country"
                rules="required"
              >
                <b-form-group
                  label="Страна"
                  label-for="country"
                  class="mb-2"
                >
                  <b-form-input
                    id="country"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.country"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="town_name"
                rules="required"
              >
                <b-form-group
                  label="Город"
                  label-for="town_name"
                  class="mb-2"
                >
                  <b-form-input
                    id="town_name"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.town_name"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col> -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Город/Регион"
                rules="required"
              >
                <b-form-group
                  label="Город/Регион"
                  label-for="town_select"
                  class="mb-2"
                >
                  <v-select
                    id="town_select"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.town_select[0]"
                    :clearable="false"
                    :get-option-label="option => option.query"
                    :state="getValidationState(validationContext)"
                    :options="options.length ? options : $store.state['app-ecommerce'].cartAddress.address.town_select"
                    @search="fetchData"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template
                        v-if="searching"
                      >
                        Поиск <em>{{ search }}</em>...
                      </template>
                      <em
                        v-else
                      >Поиск...</em>
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="street"
                rules="required"
              >
                <b-form-group
                  label="Улица"
                  label-for="street"
                  class="mb-2"
                >
                  <b-form-input
                    id="street"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.street"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="house"
                rules="required"
              >
                <b-form-group
                  label="Дом"
                  label-for="house"
                  class="mb-2"
                >
                  <b-form-input
                    id="house"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.house"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="flat"
              >
                <b-form-group
                  label="Квартира/Офис"
                  label-for="flat"
                  class="mb-2"
                >
                  <b-form-input
                    id="flat"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.flat"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="flat"
              >
                <b-form-group
                  label="Комментарий к адресу"
                  label-for="flat"
                  class="mb-2"
                >
                  <b-form-textarea
                    id="flat"
                    v-model="$store.state['app-ecommerce'].cartAddress.address.info"
                    :state="getValidationState(validationContext)"
                    rows="3"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col sm="12">
              <hr>
            </b-col>

            <b-col
              sm="12"
              class="mt-1"
            >
              <b-card-title>Доставка:</b-card-title>
            </b-col>

            <b-col
              sm="12"
            >
              <b-row class="match-height">
                <b-col
                  v-for="(delivery, key) in $store.state['app-ecommerce'].cartAddress.delivery"
                  :key="key"
                  md="4"
                >
                  <b-card
                    :img-src="server + delivery.image"
                    img-top
                    :img-alt="delivery.name"
                    :border-variant="delivery.default === true ? 'primary' : ''"
                    class="cursor-pointer"
                    @click="selectDelivery(delivery)"
                  >
                    <b-card-text>
                      <h5>{{ delivery.name }}</h5>
                    </b-card-text>
                    <b-card-text v-if="delivery.description">
                      <div v-html="delivery.description" />
                    </b-card-text>
                    <b-card-text>
                      <strong v-if="delivery.priceText"><span v-html="delivery.priceText" /></strong>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-row>
            </b-col>

            <!-- Submit Button -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                :disabled="loading"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-spinner
                    v-if="loading"
                    small
                    class="mr-50"
                  />
                  <span>Сохранить</span>
                </div>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <!-- Checkout Options -->
      <e-commerce-checkout-price-details
        label="Продолжить"
        @next-step="nextStep"
      />
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm, BCard, BCardHeader, BCardTitle, BCardText,
  BCardBody, BRow, BCol, BFormGroup, BFormInput,
  BButton, BFormInvalidFeedback, BFormTextarea,
  BSpinner, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer } from '@validations'
import { debounce } from 'lodash'
import formValidation from '@core/comp-functions/forms/form-validation'
import ECommerceCheckoutPriceDetails from './ECommerceCheckoutPriceDetails.vue'
import store from '@/store'

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,
    BFormInvalidFeedback,
    vSelect,
    BSpinner,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    ECommerceCheckoutPriceDetails,
  },
  props: {
    nextStep: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    // const ISDEKscript = document.createElement('script')
    // ISDEKscript.type = 'text/javascript'
    // ISDEKscript.charset = 'utf-8'
    // ISDEKscript.src = 'https://widget.cdek.ru/widget/widjet.js'
    // ISDEKscript.id = 'ISDEKscript'

    // document.head.appendChild(ISDEKscript)

    // const ourWidjet = new ISDEKWidjet ({
    //   defaultCity: 'Новосибирск', //какой город отображается по умолчанию
    //   cityFrom: 'Омск', // из какого города будет идти доставка
    //   country: 'Россия', // можно выбрать страну, для которой отображать список ПВЗ
    //   link: 'forpvz', // id элемента страницы, в который будет вписан виджет
    //   path: 'https://widget.cdek.ru/widget/scripts/', //директория с библиотеками
    //   servicepath: 'http://yoursite.net/service.php' //ссылка на файл service.php на вашем сайте
    // })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})
    const loading = ref(false)

    const fetchCartPriceDetail = () => {
      store.dispatch('app-ecommerce/fetchCartPriceDetail')
        .then(response => {
          const { data } = response.data
          store.commit('app-ecommerce/UPDATE_CART_TOTALS', data || [])
        })
    }

    const onSubmit = () => {
      loading.value = true
      const delivery = store.state['app-ecommerce'].cartAddress.delivery.filter(item => item.default === true)[0]
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery.value,
          price: delivery.price,
        },
      })

      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          fetchCartPriceDetail()
          loading.value = false
        })
        .catch(() => {
          loading.value = false
        })
    }

    const selectDelivery = delivery => {
      const xparam1 = JSON.stringify({
        ...store.state['app-ecommerce'].cartAddress,
        delivery: {
          value: delivery.value,
          price: delivery.price,
        },
      })
      store.dispatch('app-ecommerce/addCartAddress', {
        xparam1,
      })
        .then(() => {
          store.state['app-ecommerce'].cartAddress.delivery.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.default = false
          })
          // eslint-disable-next-line no-param-reassign
          delivery.default = true

          store.commit('app-ecommerce/UPDATE_ORDER_DATA', {
            address: {
              ...store.state['app-ecommerce'].cartAddress,
              delivery: {
                value: delivery.value,
                price: delivery.price,
              },
            },
          })

          fetchCartPriceDetail()
        })
    }

    const options = ref([])

    const fetchData = debounce(val => {
      if (val && val.length > 2) {
        store.dispatch('app-ecommerce/fetchData', {
          params: {
            q: val,
          },
        })
          .then(response => {
            const { data } = response.data
            options.value = data
          })
          .catch(() => {
            options.value = []
          })
      }
    }, 500)

    const server = process.env.VUE_APP_SERVER

    return {
      server,
      loading,
      onSubmit,
      selectDelivery,

      refFormObserver,
      getValidationState,
      resetForm,

      options,
      fetchData,

      required,
      integer,
    }
  },
}
</script>

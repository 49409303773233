<template>

  <div class="checkout-items">
    <b-card
      v-for="product in products"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <b-link :to="{ name: 'shop-details', params: { id: product.id } }">
          <b-img
            :src="product.image ? server + product.image : server + '/views/products/0/no-image.png'"
            :alt="product.name"
          />
        </b-link>
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link
              class="text-body"
              :to="{ name: 'shop-details', params: { id: product.id } }"
            >
              {{ product.name }}
            </b-link>
          </h6>
          <span class="item-company">{{ product.articul }}</span>
          <!-- <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <li
                v-for="star in 5"
                :key="star"
                class="ratings-list-item"
                :class="{'ml-25': star-1}"
              >
                <feather-icon
                  icon="StarIcon"
                  size="16"
                  :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                />
              </li>
            </ul>
          </div> -->
        </div>
        <span :class="`${product.inStockColor} mb-1`">{{ product.inStock }}</span>
        <div class="item-quantity">
          <span class="quantity-title">Количество:</span>
          <b-form-spinbutton
            v-model="product.qty"
            min="0"
            size="sm"
            class="ml-75"
            inline
          >
            <template #decrement>
              <feather-icon
                icon="MinusIcon"
              />
            </template>
            <template #increment>
              <feather-icon
                icon="PlusIcon"
              />
            </template>
          </b-form-spinbutton>
          <div
            class="spinbutton-minus"
            @click="changeQtyItemInCart('minus', product)"
          ></div>
          <div
            class="spinbutton-plus"
            @click="changeQtyItemInCart('plus', product)"
          ></div>
        </div>
        <!-- <div
          v-if="product.testModeComp && product.testModeComp === 'enable'"
          class="testMode d-flex align-items-center mt-1"
        >
          <span>Взять на тест: </span>
          <b-form-checkbox
            :checked="product.testMode === '1' ? true : false"
            name="testMode"
            switch
            inline
            class="ml-75"
            @change="changeTestMode(product)"
          />
        </div> -->
        <span :class="`delivery-date ${product.addtext1color}`">{{ product.addtext1 }}</span>
        <span :class="`${product.addtext2color}`">{{ product.addtext2 }}</span>
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              {{ product.price * product.qty }} руб.
            </h4>
          </div>
          <p
            v-if="product.cont_DiscountStr"
            class="card-text shipping"
          >
            <b-badge
              pill
              variant="light-success"
            >
              {{ product.cont_DiscountStr }}
            </b-badge>
          </p>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="removeProductFromCartAll(product)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>Удалить</span>
        </b-button>
        <!-- <b-button
          variant="primary"
          class="btn-cart move-cart"
          @click="toggleProductInWishlist(product)"
        >
          <feather-icon
            icon="HeartIcon"
            class="mr-50"
            :class="{'fill-current': product.isInWishlist !== '0'}"
          />
          <span class="text-nowrap">Избранное</span>
        </b-button> -->
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton,
  BFormSpinbutton, BBadge,
//   BFormCheckbox,d
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { formatDate } from '@core/utils/filter'
import store from '@/store'
import { useEcommerce, useEcommerceUi } from '../useEcommerce'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BFormSpinbutton,
    BBadge,
    // BFormCheckbox,
  },
  methods: {
    changeTestMode(product) {
      if (product.testMode === '0') {
        this.$store.dispatch('app-ecommerce/addProductTestMode', product.id).then(() => {
          // eslint-disable-next-line
          product.testMode = '1'
        })
      } else if (product.testMode === '1') {
        this.$store.dispatch('app-ecommerce/removeProductTestMode', product.id).then(() => {
          // eslint-disable-next-line
          product.testMode = '0'
        })
      }
    },
  },
  setup() {
    const products = ref([])

    const fetchCartPriceDetail = () => {
      store.dispatch('app-ecommerce/fetchCartPriceDetail')
        .then(response => {
          const { data } = response.data
          store.commit('app-ecommerce/UPDATE_CART_TOTALS', data || [])
        })
    }

    const { toggleProductInWishlist } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()
    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
          fetchCartPriceDetail()
        })
    }

    const fetchCartAndPriceDetail = () => {
      store.dispatch('app-ecommerce/fetchCartAndPriceDetail')
        .then(response => {
          const { data } = response.data
          if (data) {
            products.value = data[0].content
            store.commit('app-ecommerce/UPDATE_CART_TOTALS', data[0].priceDetail || [])
          }
        })
    }

    const removeProductFromCart2 = product => {
      store.dispatch('app-ecommerce/removeProductFromCart', product.id)
        .then(() => {
          if (parseInt(product.qty, 10) === 0) {
            const itemIndex = products.value.findIndex(p => p.id === product.id)
            products.value.splice(itemIndex, 1)
            // Update count in cart items state
            store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
          }
          fetchCartPriceDetail()
        })
    }

    const removeProductFromCartAll = product => {
      store.dispatch('app-ecommerce/removeProductFromCartAll', product.id)
        .then(() => {
          const itemIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(itemIndex, 1)
          // Update count in cart items state
          store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', products.value.length)
          fetchCartPriceDetail()
        })
    }

    const addProductInCart = product => {
      store.dispatch('app-ecommerce/addProductInCart', product.id).then(() => {
        fetchCartPriceDetail()
      })
    }

    const changeQtyItemInCart = (val, item) => {
      if (val === 'minus') {
        removeProductFromCart2(item)
        // eslint-disable-next-line
        item.qty = item.qty - 1
      }
      if (val === 'plus') {
        addProductInCart(item)
        // eslint-disable-next-line
        item.qty = item.qty + 1
      }
    }

    const server = process.env.VUE_APP_SERVER

    fetchCartAndPriceDetail()

    return {
      server,
      products,
      removeProductFromCartAll,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,
      changeQtyItemInCart,

      // Filter
      formatDate,
    }
  },
}
</script>
<style scoped>
  .relative {
    position: relative;
  }

  .item-quantity {
    position: relative;
  }

  .spinbutton-minus {
    position: absolute;
    left: 100px;
    top: 0;
    width: 30px;
    height: 100%;
    cursor: pointer;
  }
  .spinbutton-plus {
    position: absolute;
    top: 0;
    width: 30px;
    height: 100%;
    left: 165px;
    height: 100%;
    cursor: pointer;
  }
</style>

<template>
  <!-- Checkout Options -->
  <div
    v-if="$store.state['app-ecommerce'].cartTotals"
    class="checkout-options"
  >
    <b-card>
      <div class="price-details">
        <h6 class="price-title mt-0">
          Подробная информация о цене
        </h6>
        <ul class="list-unstyled">
          <li
            v-for="(priceDetail, key) in $store.state['app-ecommerce'].cartTotals"
            :key="key"
          >
            <div
              v-if="priceDetail.type === 'total'"
            >
              <hr>
              <div class="price-detail">
                <div class="detail-title detail-total">
                  {{ priceDetail.title }}
                </div>
                <div class="detail-amt font-weight-bolder">
                  {{ priceDetail.amt }}
                </div>
              </div>
            </div>
            <div
              v-else
              class="price-detail"
            >
              <div class="detail-title">
                {{ priceDetail.title }}
              </div>
              <div :class="`detail-amt ${priceDetail.color}`">
                {{ priceDetail.amt }}
              </div>
            </div>
          </li>
        </ul>
        <b-button
          v-if="!end"
          variant="primary"
          block
          @click="$emit('next-step')"
        >
          {{ label }}
        </b-button>
        <b-button
          v-else
          variant="primary"
          block
          @click="sendOrder()"
        >
          {{ label }}
        </b-button>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCard,
  // BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import store from '@/store'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,

    // SFC
  },
  props: {
    label: {
      type: String,
      default: () => 'Оформить заказ',
    },
    end: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const sendOrder = () => {
      store.dispatch('app-ecommerce/sendOrder')
    }

    return {
      sendOrder,
    }
  },
}
</script>

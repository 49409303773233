<template>
  <div class="list-view product-checkout mt-0">
    <!-- Products List -->
    <e-commerce-checkout-step-cart-products />

    <!-- Checkout Options -->
    <e-commerce-checkout-price-details @next-step="nextStep" />
  </div>
</template>

<script>
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'
import ECommerceCheckoutPriceDetails from './ECommerceCheckoutPriceDetails.vue'

export default {
  components: {
    ECommerceCheckoutStepCartProducts,
    ECommerceCheckoutPriceDetails,
  },
  props: {
    nextStep: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
